import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React from "react";
import { repositoryConfig } from "utils/repositoryConfig";
import PageTitle from "../components/page-title/page-title";
import SEO from "../components/seo";
import Slices from "../components/slices/slices";

function DefaultPage({ data }) {
  const node = data.allPrismicPage.nodes[0].data;

  return (
    <>
      <SEO title={node.title.text} description={node.page_description} />
      <PageTitle bg={node.title_bg} subtitle={node.subtitle}>
        {node.title.text}
      </PageTitle>
      <Slices slices={node.body} />
    </>
  );
}

export const Query = graphql`
  query ($uid: String!) {
    allPrismicPage(filter: { uid: { eq: $uid } }) {
      nodes {
        _previewable
        data {
          title {
            text
          }
          subtitle
          page_description
          title_bg {
            thumbnails {
              xs {
                url
              }
              sm {
                url
              }
              md {
                url
              }
              lg {
                url
              }
              xl {
                url
              }
              max {
                url
              }
            }
          }
          body {
            ... on PrismicPageDataBodyText {
              primary {
                text {
                  html
                }
              }
              slice_type
            }
            ... on PrismicPageDataBodyQuote {
              primary {
                quote {
                  html
                }
              }
              slice_type
            }
            ... on PrismicPageDataBodyImageText {
              slice_type
              primary {
                image {
                  thumbnails {
                    xs {
                      url
                    }
                    xs_2x {
                      url
                    }
                    xs_3x {
                      url
                    }
                  }
                  alt
                  url
                  dimensions {
                    width
                    height
                  }
                }
                text {
                  html
                }
                variant
              }
            }
            ... on PrismicPageDataBodyPaginaTeaser {
              id
              slice_type
              primary {
                page_link {
                  uid
                  type
                  url
                }
                title
                cta
                text {
                  html
                }
                variant
                image {
                  thumbnails {
                    xs {
                      url
                    }
                    xs_2x {
                      url
                    }
                    xs_3x {
                      url
                    }
                    sm {
                      url
                    }
                    sm_2x {
                      url
                    }
                    sm_3x {
                      url
                    }
                  }
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(DefaultPage, repositoryConfig);
